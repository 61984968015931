/**
 * Created by Alexandru Huszar on 3/5/2021.
 */
import React from 'react';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';

import { withStyles, Typography } from '@material-ui/core';
import SeoHelmet from 'components/SeoHelmet';
import Layout, { LayoutTitle } from 'components/Layout';

const styles = ({ palette }) => ({
  link: {
    color: palette.text.primary,
  },
});

/**
 * termeni
 */
function Termeni(props) {
  const { classes } = props;
  /**
   * Render the component
   *
   * @return {JSX.Element}
   */
  return (
    <Layout footerNode={<div />}>
      <SeoHelmet title="Contact" />
      <LayoutTitle title="Termeni și Condiții" />
      <Typography component="h1" gutterBottom variant="h5">
        1.Definiții
      </Typography>

      <Typography paragraph>
        Termenii și condițiile generale de către&nbsp;<strong>irriland</strong>
      </Typography>
      <Typography paragraph>Astfel, următorii termeni vor însemna:</Typography>
      <Typography paragraph>
        Firma –&nbsp;<strong>irriland</strong>, cu denumirea comercială&nbsp;
        <strong>IRRIGATION &amp; LANDFLOWER S.R.L</strong>, având sediul social
        în Jud: Brasov, loc: Brasov, str: Zizinului, nr:110, birou NR. P/T.10,
        CUI , nr. de înregistrare la Registrul Comerțului .
      </Typography>
      <Typography paragraph>
        Drepturi de Proprietate Intelectuală – toate drepturile imateriale cum
        ar fi know-how, dreptul de autor și drepturi în natură de autor,
        drepturile de bază de date, drepturi de proiectare, drepturi de model,
        patente, mărci înregistrate și înregistrări ale numelor de domenii
        pentru oricare din cele de mai sus.
      </Typography>
      <Typography paragraph>
        Site – domeniul{' '}
        <Link to="https://irriland.ro" className={classes.link}>
          https://irriland.ro
        </Link>{' '}
        și subdomeniile acestuia.
      </Typography>
      <Typography paragraph>Termeni și condiții</Typography>
      <Typography component="h1" gutterBottom variant="h5">
        1. Obligații firmei
      </Typography>
      <Typography paragraph>
        Informațiile prezentate pe site-urile au caracter informativ.
        Descrierile servicilor pot fi incomplete, însă firma face eforturi de a
        prezenta informațiile cele mai relevante, pentru servicile oferite sa
        fie cat mai exact posibil.
      </Typography>

      <Typography component="h1" gutterBottom variant="h5">
        2. Dreptul de proprietate intelectuală și industrială
      </Typography>

      <Typography paragraph>
        Utilizatorul înțelege dreptul de proprietate intelectuală și nu va
        dezvălui unei terțe părți sau va face publice nici una dintre
        informările primite.
      </Typography>
      <Typography paragraph>
        Toate desenele, elementele de grafică și design care apar pe site,
        numele site-ului precum și însemnele grafice sunt mărci înregistrate în
        proprietatea&nbsp;<strong>irriland</strong>&nbsp;și nu pot fi preluate,
        copiate sau folosite, fără acordul scris al proprietarului.
      </Typography>
      <Typography paragraph>
        Toate elementele de conținut cum sunt descrierile, desenele, elementele
        de grafică și design care apar pe site, incluzând dar nelimitându-se la
        logo-uri, reprezentări stilizate, simboluri comerciale, imagini statice,
        imagini dinamice, text și/sau conținut multimedia prezentate pe site,
        sunt proprietatea exclusivă a&nbsp;
        <strong>irriland</strong>, acestuia fiindu-i rezervate toate drepturile
        obținute în acest sens în mod direct sau indirect prin licențe de
        utilizare și/sau publicare.
      </Typography>
      <Typography paragraph>
        Utilizatorului nu îi este permisă copierea, distribuirea, publicarea,
        transferul către terțe părți, modificarea și/sau altfel alterarea,
        utilizarea, legarea la, expunerea, includerea oricărui element conținut
        de mai sus în orice alt context decât cel original intenționat de&nbsp;
        <strong>irriland</strong>, includerea oricărui element de conținut în
        afara Site-ului, îndepărtarea însemnelor care semnifică dreptul de autor
        al&nbsp;
        <strong>irriland</strong>&nbsp;asupra elementelor de conținut precum
        participarea la transferul, distribuția unor materiale realizate prin
        reproducerea, modificarea sau afișarea elementelor de conținut, decât cu
        acordul scris expres al&nbsp;
        <strong>irriland</strong>.
      </Typography>

      <Typography component="h1" gutterBottom variant="h5">
        3. Drepturile asupra conținutului site-ului
      </Typography>

      <Typography paragraph>
        Întreg continutul site-ului&nbsp;si elementele de grafica, inclusiv dar
        fara a se limita la acestea, respectiv tot continutul in format text,
        precum si sursele tehnice ale tuturor serviciilor si facilitatilor
        prezente si viitoare – cu exceptia cazului cand este mentionat expres un
        alt proprietar – sursele paginilor dar si orice alt material, transmis
        sub orice forma de si catre Utilizatori (prin vizualizare directa pe
        site, prin newslettere etc.) apartin&nbsp;
        <strong>irriland</strong>.
      </Typography>
      <Typography paragraph>
        Continutul site-ului, indiferent de zona in care se afla in site si
        indiferent de tip, poate fi utilizat exclusiv in scopuri personale.
        Orice utilizare de continut de catre terte persoane in alte scopuri
        decat cel personal, se poate face numai cu acordul scris, expres si
        prealabil al&nbsp;<strong>irriland</strong>. Prin urmare, este interzisa
        copierea, preluarea, reproducerea, publicarea, transmiterea, vanzarea,
        distributia partiala, integrala sau modificata a continutului acestui
        site sau a oricarei parti a acestuia efectuate in alte scopuri decat cel
        personal, cu urmatoarele exceptii:
      </Typography>
      <Typography paragraph>
        (i) este permisa reproducerea (pe site-uri noncomerciale, forumuri,
        articole din presa etc.) a unor mici fragmente din articolele publicate
        (max. 400 de caractere), fiind obligatorie specificarea sursei
        informatiilor preluate, cu link, sub urmatoarea forma: (Sursa: nume site
        – link catre continutul site-ului).
      </Typography>
      <Typography paragraph>
        (ii) sunt permise link-urile catre site-ul{' '}
        <Link to="https://irriland.ro" className={classes.link}>
          https://irriland.ro
        </Link>
        , iar specificarea sursei informatiilor se va face dupa fiecare link sau
        in finalul articolului, dupa cum urmeaza: „Informatii furnizate prin
        amabilitatea <strong>irriland</strong> – link catre continutul
        site-ului)
      </Typography>
      <Typography paragraph>
        Utilizatorii se obliga sa respecte toate drepturile de autor si toate
        drepturile conexe acestuia si orice alte drepturi de proprietate
        intelectuala pe care Administratorul site-ului si partenerii sai le
        detin asupra/in legatura cu site-ul &nbsp;
        <Link to="https://irriland.ro" className={classes.link}>
          https://irriland.ro
        </Link>
        .
      </Typography>
      <Typography paragraph>
        <strong>irriland</strong>&nbsp;isi rezerva dreptul de a actiona in
        instanta orice persoana si/sau entitate care incalca in orice mod
        prevederile de mai sus. Cererile de utilizare a continutului site-ului
        in orice scop altul decat cel personal pot fi facute prin e-mail la
        adresa mail <strong>irilandbv@gmail.com</strong>.ro, cu specificatia „In
        atentia agenției”.
      </Typography>
      <Typography paragraph>
        Orice persoana care transmite sau publica in orice mod informatii ori
        materiale catre site&nbsp;isi asuma obligatia de a nu prejudicia in nici
        un mod drepturile de autor pe care o terta persoana le-ar putea invoca
        in legatura cu materialele si informatiile transmise in orice mod catre
        site, iar persoanele care expediaza in orice mod informatii ori
        materiale inteleg si accepta ca incalcarea in orice mod a acestei
        obligatii nu poate angaja in niciun mod raspunderea&nbsp;
        <strong>irriland</strong>, ci numai raspunderea persoanelor respective.
      </Typography>
      <Typography paragraph>
        <strong>irriland</strong>&nbsp;poate derula in orice moment campanii
        publicitare si/sau promotii in orice sectiune din site, fara ca aceasta
        operatiune sa necesite acordul Utilizatorilor site-ului. Spatiile si
        dimensiunea campaniilor publicitare si promotiilor nu necesita acordul
        Utilizatorilor site-ului si pot fi schimbate oricand fara a necesita
        anuntarea prealabila.
      </Typography>
      <Typography paragraph>
        <strong>irriland</strong>&nbsp;nu isi asuma responsabilitatea pentru
        prejudiciile sau pierderile rezultate din campaniile publicitare sau
        promotiile organizate in site, altele decat cele organizate strict pe
        site.
      </Typography>

      <Typography component="h1" gutterBottom variant="h5">
        4. Limitarea răspunderii administratorului site-ului
      </Typography>
      <Typography paragraph>
        <strong>irriland</strong>&nbsp;nu isi asuma obligatia si nu garanteaza
        implicit sau expres, pentru continutul site-ului, respectiv pentru
        continutul oferit de partenerii sai sau de Utilizatorii site-ului. Cu
        toate acestea,&nbsp;<strong>irriland</strong>&nbsp;va depune toate
        eforturile rezonabile pentru a asigura acuratetea si maniera
        profesionista in care vor fi furnizate informatiile pe site, pentru a
        dobandi si mentine increderea Utilizatorilor in site. In acest
        sens,&nbsp;<strong>irriland</strong>&nbsp;va incerca sa corecteze in cel
        mai scurt timp posibil erorile si omisiunile semnalate.
      </Typography>
      <Typography paragraph>
        Administratorul site-ului nu ofera niciun fel de garantii pentru
        continutul site-ului si in nicio situatie nu poate fi tinut raspunzator
        pentru nicio pierdere sau prejudiciu ce ar putea rezulta din utilizarea
        oricarei parti/secvente/pagini de pe site sau din imposibilitatea
        utilizarii acesteia, indiferent de cauza acesteia ori din interpretarea
        eronata a oricaror prevederi ale continutului site-ului.
      </Typography>
      <Typography paragraph>
        Informatiile furnizate prin intermediul site-ului sunt oferite cu
        buna-credinta, din surse apreciate ca fiind de incredere. In cazul in
        care vreunul din articolele publicate sau orice alta informatie intra
        sub incidenta legii dreptului de autor, rugam Utilizatorii sa ne
        contacteze la adresa de e-mail&nbsp;
        <strong>irilandbv@gmail.com</strong>, pentru a putea lua masurile ce
        impun. Totodată, Utilizatorii trebuie sa aibă în vedere faptul ca
        informatia prezentata poate include eventuale informatii inexacte (de
        ex.: date de ordin tehnic sau erori de tastat).&nbsp;Administratorul
        site-ului va face toate diligentele necesare pentru a corecta in cel mai
        scurt timp posibil aceste aspecte.
      </Typography>
      <Typography paragraph>
        Utilizatorii inteleg si accepta faptul ca&nbsp;
        <strong>irriland</strong>&nbsp;nu garanteaza:
      </Typography>
      <Typography paragraph>
        - ca informatiile continute pe site sunt pe deplin complete;
      </Typography>
      <Typography paragraph>
        - ca informatiile introduse de Utilizatorii website-ului sunt reale,
        corecte si nu isi asuma raspunderea pentru modul in care vizitatorii le
        folosesc;
      </Typography>
      <Typography paragraph>
        - ca informatiile sau serviciile de pe site &nbsp;vor satisface toate
        cerintele Utilizatorilor, iar pentru folosirea inadecvata a acestora
        Utilizatorii isi asuma intreaga responsabilitate;
      </Typography>
      <Typography paragraph>
        - pentru rezultatele obtinute de Utilizatori ca urmare a folosirii
        informatiilor sau serviciilor disponibile prin intermediul site-ului
        utilizarea informatiilor si a serviciilor facandu-se de catre
        Utilizatori pe propria raspundere;
      </Typography>
      <Typography paragraph>
        De asemenea, Utilizatorii inteleg si accepta faptul ca&nbsp;
        <strong>irriland</strong>&nbsp;nu este responsabila pentru eventuale
        inadvertente, erori sau omisiuni in cadrul informatiilor furnizate pe
        site de catre Utilizatori. Totodata, Utilizatorii inteleg si accepta
        ca&nbsp;<strong>irriland</strong>&nbsp;este absolvita de orice
        raspundere pentru mesajele publicitare postate pe site sau prin
        intermediul serviciilor oferite prin site. In mod expres, Utilizatorii
        site-ului sunt de acord sa exonereze de raspundere&nbsp;
        <strong>irriland</strong>&nbsp;pentru orice actiune judiciara sau
        extrajudiciara, care provine ca urmare a utilizarii incorecte sau
        frauduloase a site-ului.
      </Typography>
      <Typography paragraph>
        Pentru cazuri de forta majora,&nbsp;<strong>irriland</strong>
        &nbsp;si/sau operatorii, directorii, angajatii, sucursalele, filialele
        si reprezentantii sai, este exonerata total de orice raspundere.
        Cazurile de forta majora includ, dar nu se limiteaza la, erori de
        functionare ale echipamentului tehnic al&nbsp;
        <strong>irriland</strong>, lipsa functionarii conexiunii la internet,
        lipsa functionarii conexiunilor de telefon, virusii informatici, accesul
        neautorizat in sistemele Site-ului, erorile de operare, etc.
      </Typography>
      <Typography paragraph>
        Utilizatorii sunt de acord sa protejeze si sa asigure&nbsp;
        <strong>irriland</strong>&nbsp;si/sau pe operatorii, directorii,
        angajatii, sucursalele, filialele si reprezentantii sai de si impotriva
        oricaror cereri, pretentii, actiuni, impuneri, pierderi, daune, costuri
        (incluzand, fara nici un fel de limitare, onorariile avocatilor),
        cheltuieli, judecati, decizii, amenzi, regularizari sau alte obligatii
        rezultate sau relationate cu orice alta actiune a Utilizatorilor in
        legatura cu utilizarea site-ului sau a serviciilor oferite prin
        intermediul acestuia.
      </Typography>
      <Typography paragraph>
        <strong>irriland</strong>&nbsp;nu ofera nicio garantie, nici in mod
        expres si nici implicit, in ceea ce priveste inclusiv, dar fara limitare
        la functionarea site-ului&nbsp;
        <Link to="https://irriland.ro" className={classes.link}>
          https://irriland.ro
        </Link>
        , informatia, continutul, materialele sau produsele de pe site, precum
        si potrivirea lor pentru un anumit scop. Utilizatorii sunt de acord in
        mod expres ca folosirea acestui site si aplicarea informatiilor se face
        pe propriul lor risc.
      </Typography>

      <Typography component="h1" gutterBottom variant="h5">
        5. Politica de cookie-uri
      </Typography>

      <Typography paragraph>
        Un cookie este un fişier text care conţine mici fragmente de informaţii
        trimise navigatorului tău şi memorate în computerul, telefonul tău mobil
        sau alt dispozitiv, atunci când vizitezi un site. Acest fişier cookie
        trimite informaţii înapoi către site ori de câte ori îl revizitezi.
      </Typography>
      <Typography paragraph>
        Cookie-urile pot fi&nbsp;<strong>permanente</strong>&nbsp;(cunoscute
        drept cookie-uri persistente), care rămân în computerul tău până când le
        ştergi, sau&nbsp;<strong>temporare</strong>&nbsp;(cunoscute drept
        cookie-uri pentru sesiune) care sunt valabile doar până când închizi
        fereastra navigatorului tău. Cookie-urile pot fi ale părţii-gazdă
        (first-party), care sunt configurate de site-ul pe care îl vizitezi, sau
        ale unor părţi terţe, care sunt configurate de un site diferit de cel pe
        care îl vizitezi.
      </Typography>
      <Typography paragraph>
        Cum utilizeaza&nbsp;<strong>irriland</strong>&nbsp;cookie-urile?
      </Typography>
      <Typography paragraph>
        Utilizăm cookie-urile pentru a îmbunătăţi funcţionalitatea site-urilor
        noastre, pentru a te ajuta să navighezi mai eficient de la o pagină la
        alta, pentru a memora preferinţele tale şi, în general, pentru a
        îmbunătăţi experienţa utilizatorilor. Cookie-urile pe care le folosim pe
        site-urile noastre pot fi din următoarele categorii:
      </Typography>
      <ul>
        <li>
          <strong>Cookie-uri strict necesare:</strong>
        </li>
      </ul>
      <Typography paragraph>
        Aceste cookie-uri sunt esenţiale pentru ca tu să poţi naviga pe site şi
        să foloseşti serviciile pe care le-ai solicitat, cum ar fi accesarea
        zonelor sigure ale site-ului.
      </Typography>
      <ul>
        <li>
          <strong>Cookie-uri pentru performanţă:</strong>
        </li>
      </ul>
      <Typography paragraph>
        Aceste cookie-uri colectează informaţii despre cum utilizează
        vizitatorii un site, de exemplu care sunt paginile cele mai vizitate.
        Aceste cookie-uri nu colectează informaţii care identifică vizitatori
        individuali. Toate informaţiile colectate de aceste cookie-uri sunt
        cumulate şi anonime.
      </Typography>
      <Typography paragraph>
        Folosim aceste cookie-uri pentru a realiza statistici despre cum sunt
        utilizate site-urile noastre
      </Typography>
      <Typography paragraph>
        Aceste cookie-uri pot fi permanente sau temporare, ale părţii-gazdă sau
        ale unor părţi terţe. Pe scurt, aceste cookie-uri colectează informaţii
        anonime despre paginile vizitate şi reclamele vizualizate.
      </Typography>

      <ul>
        <li>
          <strong>Cookie-uri pentru publicitate:</strong>
        </li>
      </ul>
      <Typography paragraph>
        Aceste cookie-uri sunt utilizate pentru a limita de câte ori vezi o
        reclamă.
      </Typography>
      <Typography paragraph>
        Cookie-urile pentru publicitate sunt folosite pentru a gestiona
        publicitatea pe tot site-ul.
      </Typography>
      <Typography paragraph>
        Cookie-urile pentru publicitate sunt plasate de terţe părţi, cum ar fi
        companiile de publicitate şi agenţii lor, şi pot fi cookie-uri
        permanente sau temporare. Pe scurt, ele sunt legate de serviciile de
        publicitate furnizate pe site-ul nostru de terţe părţi.
      </Typography>

      <ul>
        <li>
          <strong>Cookie-uri pentru sociale:</strong>
        </li>
      </ul>
      <Typography paragraph>
        Aceste cookies sunt folosite de retelele de socializare (Facebook,
        Twitter, Google+, YouTube, Instagram, Pinterest, etc) si permit
        distribuirea continutului de pe{' '}
        <Link to="https://irriland.ro" className={classes.link}>
          https://irriland.ro
        </Link>{' '}
        pe respectivele retele.&nbsp;
        <strong>irriland</strong>.ro nu controleaza aceste cookies, deci, pentru
        mai multe informatii despre cum functioneaza, te rugam sa verifici
        paginile retelelor de socializare.
      </Typography>
      <Typography paragraph>
        Cum să administrezi &amp; ştergi cookie-uri
      </Typography>
      <Typography paragraph>
        Dacă vrei să impui restricţii, să blochezi sau să ştergi fişiere cookie,
        poţi s-o faci modificând setările navigatorului tău de web.
        Utilizarea&nbsp;
        <Link to="https://irriland.ro" className={classes.link}>
          https://irriland.ro
        </Link>
        &nbsp;fără respingerea cookie-urilor sau a tehnologiilor similare denotă
        consimţământul vizitatorilor pentru utilizarea de către noi a unor
        astfel de tehnologii şi pentru prelucrarea informaţiilor.
      </Typography>

      <Typography component="h1" gutterBottom variant="h5">
        6. Prelucrarea datelor cu caracter personal
      </Typography>
      <Typography paragraph>
        Pentru a asigura respectarea dreptului utilizatorilor site-ului la
        protecția datelor cu caracter personal, am implementat măsuri specifice
        de protecție, luând în considerare legislația română, precum și
        cerințele stabilite de Regulamentul (UE) 2017/679 –&nbsp; aplicabil în
        întreaga Uniune Europeană începând cu data de 25 mai 2018 („
        <strong>Regulamentul</strong>”).
      </Typography>
      <Typography paragraph>
        Datele cu caracter personal reprezintă orice informație prin care dvs.
        puteți fi identificat, în special printr-un element de identificare cum
        ar fi un nume, un număr de identificare, date de localizare, un
        identificator online sau unul sau mai multe elemente aflate în legătură
        cu identitatea dvs. fizică, fiziologică, genetică, psihică, economică,
        culturală sau socială.
      </Typography>
      <Typography paragraph>
        Societatea noastră&nbsp;<strong>ia toate măsurile necesare</strong>
        &nbsp;pentru a asigura respectarea dreptului dvs. la protecția datelor
        cu caracter personal, iar prezentele condiţii reprezintă&nbsp;
        <strong>notificarea</strong>stabilită de art. 13 sau 14 din Regulament
        prin care &nbsp;explicăm de ce colectăm datele dvs. cu caracter
        personal, modul în care protejăm aceste date, precum și care sunt
        drepturile dvs. în legătură cu această colectare de date.
      </Typography>
      <Typography paragraph>
        <strong>
          Vă încurajăm să citiți cu atenție documentul și să ne solicitați orice
          informație suplimentară sau orice lămurire pe care o considerați
          necesară, cu privire la conținutul acestei informări.
        </strong>
      </Typography>

      <Typography paragraph>
        În enumerările de mai jos vă prezentăm în mod detaliat ce date cu
        caracter personal colectate precum și temeiul, scopul și durata
        colectării acestora.&nbsp;&nbsp;Furnizarea datelor indicate mai jos nu
        este obligatorie pentru a vizualiza Website-ul, cu excepția celor care
        vizează Cookies.
      </Typography>
      <Typography paragraph>
        Datele indicate sunt necesare numai pentru a presta servicii specifice
        pentru utilizatorii care le transmit.
      </Typography>
      <Typography paragraph>
        Cookies, marcaje de timp (data și ora accesării), istoricul navigării pe
        site
      </Typography>
      <Typography paragraph>
        <strong>
          Scopurile colectării datelor având caracter personal:&nbsp;
        </strong>
      </Typography>
      <Typography paragraph>
        Pentru monitorizarea traficului site-ului și a istoricului accesărilor
        acestuia
      </Typography>

      <Typography paragraph>
        Vom stoca datele dvs. cu caracter personal pentru o perioadă care&nbsp;
        <strong>
          nu depășește perioada necesară îndeplinirii scopurilor în care sunt
          prelucrate datele
        </strong>
        , iar, în cazurile în care avem o obligația legală de a păstra datele
        dvs. cu caracter personal o anumită perioadă de timp, perioada de
        păstrare va fi cea prevăzută de lege. În funcție de situația concretă,
        această perioadă va varia, putând fi cuprinsă între
        <strong>&nbsp;1 zi</strong>&nbsp;și&nbsp;<strong>10 ani</strong>.
      </Typography>
      <Typography paragraph>
        Pentru o bună înțelegere a modului de păstrare, a locației în care
        datele dvs. cu caracter personal vor fi păstrate și a perioadei exacte
        pentru care aceste date vor fi păstrate în evidențele sau în sistemele
        noastre.
      </Typography>
      <Typography component="h1" gutterBottom variant="h6">
        <strong>&nbsp;</strong>
        <span>
          Drepturile utilizatorilor cu privire la datele cu caracter personal
        </span>
        și<span>&nbsp;exercitarea acestora</span>
      </Typography>
      <Typography paragraph>
        În vederea protejării la un standard cât mai ridicat a datelor
        utilizatorul are serie de drepturi reglementate de lege, pe care le
        prezentăm pe scurt în cele ce urmează, rugându-vă ca pentru orice
        detalii suplimentare să nu ezitați să contactați responsabilul cu
        protecția datelor cu caracter personal din cadrul Societății noastre,
        utilizând detaliile de contact următoare:&nbsp;
        <strong>irilandbv@gmail.com</strong>, Jud: Brasov, loc: Brasov, str:
        Zizinului, nr:110,birou NR. P/T.10,&nbsp;telefon&nbsp;.
      </Typography>
      <Typography paragraph>
        De asemenea, utilizatorul are dreptul să obțina orice informații
        suplimentare relevante (cum ar fi motivul prelucrării datelor cu
        caracter personal, categoriile de date cu caracter personal pe care le
        colectăm, informații privind prelucrarea și divulgarea acestor date și
        orice alte asemenea informații).
      </Typography>
      <Typography paragraph>
        Utilizatorul are dreptul de a depune o plângere la Autoritatea Națională
        de Supraveghere a Prelucrării Datelor cu Caracter Personal cu referire
        la aspectele ce privesc prelucrarea datelor cu caracter personal de
        către Societatea noastră.
      </Typography>
      <Typography component="h1" gutterBottom variant="h6">
        <strong>&nbsp;</strong>
        <strong>Exercitarea drepturilor</strong>
      </Typography>
      <Typography paragraph>
        Pentru ca utilizatorul sa își poată exercita drepturile precizate mai
        sus, pentru a ne adresa orice întrebare referitoare la aceste drepturi
        sau pentru a ne cere lămuriri cu privire la oricare dintre prevederile
        acestei informări, vă rugăm să ne contactați oricând, utilizând
        informațiile de contact.
      </Typography>
      <Typography paragraph>
        Cum se pot transmite cererile sau plângerile – persoana de contact
      </Typography>
      <Typography paragraph>
        Pentru a afla mai multe informații cu privire la datele cu caracter
        personal, cu privire la modul în care datele sunt colectate, prelucrate
        și protejate sau pentru a cere orice lămuriri referitoare la cele
        menționate în acești Temeri și Condiții orice utilizator poate contacta
        la orice moment responsabilul cu protecția datelor cu caracter personal
        din cadrul companiei, adresându-se prin mail la&nbsp;
        <strong>irilandbv@gmail.com</strong> sau prin corespondenta postala la
        Jud: Brasov, loc: Brasov, str: Zizinului, nr:110,birou NR. P/T.10.
      </Typography>
      <Typography paragraph>Forță majoră</Typography>
      <Typography paragraph>
        Nici una din părți nu va fi răspunzătoare pentru neexecutarea
        obligațiilor sale contractuale, dacă o astfel de neexecutare este
        datorată unui eveniment de forță majoră. Forța majoră este evenimentul
        imprevizibil, în afara controlului părților și care nu poate fi evitat.
      </Typography>

      <Typography component="h1" gutterBottom variant="h5">
        7. Legea aplicabilă
      </Typography>

      <Typography paragraph>
        Prezentul contract este supus legii Române. Eventualele litigii apărute
        între&nbsp;
        <strong>irriland</strong>&nbsp;și utilizatori/clienți/cumpărători se
        rezolva pe cale amiabilă sau, în cazul în care aceasta nu va fi
        posibilă, litigiile vor fi soluționate de instanțele judecătorești
        Române competente.
      </Typography>

      <Typography component="h1" gutterBottom variant="h5">
        8. Modificarea termenilor și condițiilor
      </Typography>
      <Typography paragraph>
        <strong>irriland</strong>&nbsp;are dreptul de a modifica oricând și în
        orice mod oricare dintre prevederile cuprinse în Termeni și condiții sau
        Termenii și Condițiile în întregime, fără nicio notificare prealabila și
        fără a fi obligata sa îndeplinească vreo alta formalitate fata de
        Utilizatori. Orice modificare este acceptata deplin și necondiționat de
        către Utilizatorii site-ului prin simpla utilizare sau accesare a
        site-ului sau a oricărei facilitați oferite de site, intervenite oricând
        după operarea modificării, iar neacceptarea oricărei modificări atrage
        obligația respectivului Utilizator de a înceta de îndată accesarea
        site-ului și/sau utilizarea în orice mod a serviciilor oferite prin
        intermediul acestuia.
      </Typography>
    </Layout>
  );
}

Termeni.propTypes = {
  classes: PropTypes.object,
};

Termeni.defaultProps = {
  classes: {},
};

export default withStyles(styles)(Termeni);
